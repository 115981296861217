import Tooltip from "@mui/material/Tooltip"
import AntdTooltip from "antd/es/tooltip"
import React, { useEffect, useRef, useState } from "react"

import "./OverflowTooltip.less"

// Adapted from https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis
export interface OverflowTooltip {
  title: React.ReactNode
  children: React.ReactNode
}

export const OverflowTooltip = (props: OverflowTooltip) => {
  // Create Ref
  const textElementRef = useRef<HTMLDivElement>(null)

  const compareSize = () => {
    // TODO(michael): sus.... idk man
    setTimeout(() => {
      // https://stackoverflow.com/questions/68250396/why-is-my-scrollwidth-showing-wrong-number-react-js
      const compare =
        textElementRef.current &&
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      setHover(compare ?? false)
    }, 200)
  }

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
    // remove resize listener again on "componentWillUnmount"
    return () => {
      window.removeEventListener("resize", compareSize)
    }
  }, [textElementRef.current?.scrollWidth, textElementRef.current?.clientWidth])

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false)

  // This is pretty sus
  // TODO:(michael) clean up later
  return (
    <AntdTooltip placement="top">
      <Tooltip
        title={props.title}
        disableHoverListener={!hoverStatus}
        componentsProps={{
          tooltip: {
            className: "OverflowTooltip",
          },
        }}
        placement="top"
      >
        <div
          ref={textElementRef}
          style={{
            display: "inline",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.children}
        </div>
      </Tooltip>
    </AntdTooltip>
  )
}
