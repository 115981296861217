import humps from "humps"

import { caseConverterClient } from "~/shared/api/client"
import { Hex, defaultStyles } from "~/shared/styles/defaultStyles"

export interface Customization {
  id: number
  label: string
  customizationKey: string
  isDefault: boolean
  options: CustomizationOptions
}

export type MappingStrategy = "exact" | "fuzzy" | "historical_user" | "historical_org"
export enum RowDeletionStrategy {
  SELECTION = "selection",
  ERRORS = "errors",
}

export enum AiSuggestedMappingTypes {
  Column = "column",
  Picklist = "picklist",
}

export enum WarningBehavior {
  ImportAsError = "importAsError",
  ImportAsClean = "importAsClean",
}

export const CustomizationKeyToI18nKey: { [key: string]: string } = {
  backButtonText: "Embedding.Footer.DefaultCancel",
  nextButtonText: "Embedding.Footer.DefaultNext",
  uploadPaneHeaderText: "Embedding.Upload.Header",
  uploaderHeaderText: "Embedding.Upload.Prompt",
  uploaderSubheaderText: "Embedding.Upload.PromptCaption",
  setHeaderPaneHeaderText: "Embedding.Upload.Prompt",
  mappingPaneHeaderText: "MappingHeader.Title",
  mappingUploadedColumnText: "MappingHeader.UploadedColumnTitle",
  mappingTemplateColumnText: "MappingHeader.TemplateColumnTitle",
  picklistMappingHeaderText: "PicklistMapping.Title",
  picklistMappingSubheaderText: "PicklistMapping.Subtitle",
  picklistMappingUploadedColumnText: "PicklistMapping.UploadedTitle",
  picklistMappingTemplateColumnText: "PicklistMapping.TemplateTitle",
  cleaningPaneHeaderText: "Embedding.CleaningHeader",
  cleaningConfirmButtonText: "Embedding.ImportAction",
  ffCancelButtonText: "FileFeedsEmbedding.Footer.Cancel",
  ffSaveButtonText: "FileFeedsEmbedding.Footer.Save",
  ffHeaderText: "FileFeedsEmbedding.Header.Title",
  ffUploaderHeaderText: "FileFeedsEmbedding.Upload.Header",
  ffUploaderSubheaderText: "FileFeedsEmbedding.Upload.Subheader",
}

export interface CustomizationOptions {
  /**
   * styles
   */
  // GENERAL
  primaryColor?: Hex
  backgroundPrimaryColor?: Hex
  backgroundSecondaryColor?: Hex
  headerColor?: Hex
  footerColor?: Hex
  borderColor?: Hex
  successColor?: Hex
  warningColor?: Hex
  errorColor?: Hex

  // BUTTONS
  buttonBorderRadius?: string
  buttonPrimaryFillColor?: Hex
  buttonPrimaryStrokeColor?: Hex
  buttonPrimaryTextColor?: Hex
  buttonSecondaryFillColor?: Hex
  buttonSecondaryStrokeColor?: Hex
  buttonSecondaryTextColor?: Hex
  buttonTertiaryFillColor?: Hex
  buttonTertiaryStrokeColor?: Hex
  buttonTertiaryTextColor?: Hex
  buttonAlertFillColor?: Hex
  buttonAlertStrokeColor?: Hex
  buttonAlertTextColor?: Hex

  // FONTS
  fontUrl?: string
  fontFamily?: string
  fontColorPrimary?: Hex
  fontColorSecondary?: Hex
  fontColorPlaceholder?: Hex

  /**
   * options
   */
  // IMPORTER OPTIONS
  hideLogo?: boolean
  modalFullscreen?: boolean
  hideCloseButton?: boolean
  modalMaskColor?: Hex
  modalBorderRadius?: string

  // UPLOAD PANE
  fileSizeLimit?: number
  illustrationUrl?: string | null // used after saving, as setting (from server)
  illustration?: { file?: File; delete?: boolean } | null // used when saving illustration file
  uploaderShowSidebar?: boolean
  uploaderSidebarDetails?: "required" | "all"
  uploaderShowSidebarBanner?: boolean
  uploaderSidebarBannerText?: string | null
  includeExcelTemplate?: boolean

  // SELECT HEADER ROW PANE
  skipHeaderRow?: "always" | "detect" | "never"

  // MAP COLUMNS PANE
  includeUnmappedColumns?: boolean
  mappingStrategy?: MappingStrategy[]
  skipMapping?: MappingStrategy[]
  aiSuggestedMappings?: AiSuggestedMappingTypes[]
  oneClickMode?: boolean
  mappingShowSidebar?: boolean
  mappingSidebarDetails?: "required" | "all"
  mappingShowSidebarBanner?: boolean
  mappingSidebarBannerText?: string | null

  // REVIEW AND FINALIZE PANE
  autofixAfterMapping?: boolean
  acceptCodeHookSuggestions?: boolean
  preventRowDeletion?: RowDeletionStrategy[]
  importErrorUX?: "blockIfErrors" | "promptIfErrors" | "ignoreErrors"
  warningBehavior?: WarningBehavior
  skipCleaning?: boolean
  allowEmptyImports?: boolean
  allowRowAddition?: boolean
  importMaxRowLimit?: number | null
  importRowLimitHeader?: string | null
  importRowLimitDescription?: string | null

  // EDUCATION WIDGET
  showUploadEducationWidget?: boolean
  uploadEducationWidgetMessage?: string
  uploadEducationWidgetAutoOpen?: boolean
  showSetHeaderEducationWidget?: boolean
  setHeaderEducationWidgetMessage?: string
  setHeaderEducationWidgetAutoOpen?: boolean
  showMappingEducationWidget?: boolean
  mappingEducationWidgetMessage?: string
  mappingEducationWidgetAutoOpen?: boolean
  showCleaningEducationWidget?: boolean
  cleaningEducationWidgetMessage?: string
  cleaningEducationWidgetAutoOpen?: boolean

  /**
   * Text overrides
   */
  backButtonText?: string | null
  nextButtonText?: string | null
  uploadPaneHeaderText?: string | null
  uploaderHeaderText?: string | null
  uploaderSubheaderText?: string | null
  setHeaderPaneHeaderText?: string | null
  mappingPaneHeaderText?: string | null
  mappingUploadedColumnText?: string | null
  mappingTemplateColumnText?: string | null
  cleaningPaneHeaderText?: string | null
  cleaningConfirmButtonText?: string | null
  picklistMappingHeaderText?: string | null
  picklistMappingSubheaderText?: string | null
  picklistMappingUploadedColumnText?: string | null
  picklistMappingTemplateColumnText?: string | null

  // File feed text overrides
  ffCancelButtonText?: string | null
  ffSaveButtonText?: string | null
  ffHeaderText?: string | null
  ffUploaderHeaderText?: string | null
  ffUploaderSubheaderText?: string | null
}

export const DEFAULT_OPTIONS = Object.freeze<CustomizationOptions>({
  /**
   * styles
   */
  // GENERAL
  primaryColor: defaultStyles.ColorPrimary100,
  backgroundPrimaryColor: defaultStyles.ColorBackgroundPrimary,
  backgroundSecondaryColor: defaultStyles.ColorBackgroundSecondary,
  headerColor: defaultStyles.ColorHeader,
  footerColor: defaultStyles.ColorFooter,
  borderColor: defaultStyles.ColorBorder,
  successColor: defaultStyles.ColorSuccessGreen100,
  warningColor: defaultStyles.ColorWarningYellow100,
  errorColor: defaultStyles.ColorErrorRed100,

  modalFullscreen: false,
  hideCloseButton: false,
  modalMaskColor: defaultStyles.ColorModalMask,
  modalBorderRadius: defaultStyles.ModalBorderRadius,

  // BUTTONS
  buttonBorderRadius: defaultStyles.ButtonBorderRadius,
  buttonPrimaryFillColor: defaultStyles.ButtonPrimaryFillColor,
  buttonPrimaryStrokeColor: defaultStyles.ButtonPrimaryStrokeColor,
  buttonPrimaryTextColor: defaultStyles.ButtonPrimaryTextColor,
  buttonSecondaryFillColor: defaultStyles.ButtonSecondaryFillColor,
  buttonSecondaryStrokeColor: defaultStyles.ButtonSecondaryStrokeColor,
  buttonSecondaryTextColor: defaultStyles.ButtonSecondaryTextColor,
  buttonTertiaryFillColor: defaultStyles.ButtonTertiaryFillColor,
  buttonTertiaryStrokeColor: defaultStyles.ButtonTertiaryStrokeColor,
  buttonTertiaryTextColor: defaultStyles.ButtonTertiaryTextColor,
  buttonAlertFillColor: defaultStyles.ButtonAlertFillColor,
  buttonAlertStrokeColor: defaultStyles.ButtonAlertStrokeColor,
  buttonAlertTextColor: defaultStyles.ButtonAlertTextColor,

  // FONTS
  // fontUrl: defaultStyles.FontUrl
  // fontFamily: defaultStyles.FontFamily
  fontColorPrimary: defaultStyles.FontColorPrimary,
  fontColorSecondary: defaultStyles.FontColorSecondary,
  fontColorPlaceholder: defaultStyles.FontColorPlaceholder,
  /**
   * options
   */
  // IMPORTER OPTIONS
  hideLogo: false,
  illustration: null,
  illustrationUrl: null,
  includeExcelTemplate: false,
  uploaderShowSidebar: false,
  uploaderSidebarDetails: "required",
  uploaderShowSidebarBanner: false,
  uploaderSidebarBannerText: null,
  mappingShowSidebar: false,
  mappingSidebarDetails: "required",
  mappingShowSidebarBanner: false,
  mappingSidebarBannerText: null,
  showUploadEducationWidget: false,
  showSetHeaderEducationWidget: false,
  showMappingEducationWidget: false,
  showCleaningEducationWidget: false,

  // NOTE: These constants are copied in the backend in models/customization.rb
  // and should be kept in sync.
  fileSizeLimit: undefined,
  mappingStrategy: ["exact", "historical_user", "historical_org"],
  aiSuggestedMappings: [],
  oneClickMode: false,
  skipMapping: ["exact", "historical_user", "historical_org"],
  skipCleaning: false,
  allowEmptyImports: false,
  allowRowAddition: false,
  acceptCodeHookSuggestions: false,
  autofixAfterMapping: false,
  includeUnmappedColumns: false,
  preventRowDeletion: [],
  importErrorUX: "blockIfErrors",
  warningBehavior: WarningBehavior.ImportAsError,
  skipHeaderRow: "detect",
  importMaxRowLimit: null,
  importRowLimitHeader: null,
  importRowLimitDescription: null,

  /**
   * Text overrides
   */
  backButtonText: null,
  nextButtonText: null,
  uploadPaneHeaderText: null,
  uploaderHeaderText: null,
  uploaderSubheaderText: null,
  setHeaderPaneHeaderText: null,
  mappingPaneHeaderText: null,
  mappingUploadedColumnText: null,
  mappingTemplateColumnText: null,
  cleaningPaneHeaderText: null,
  cleaningConfirmButtonText: null,
  picklistMappingHeaderText: null,
  picklistMappingSubheaderText: null,
  picklistMappingUploadedColumnText: null,
  picklistMappingTemplateColumnText: null,
  ffCancelButtonText: null,
  ffSaveButtonText: null,
  ffHeaderText: null,
  ffUploaderHeaderText: null,
  ffUploaderSubheaderText: null,
})

export const SELF_SERVE_DEFAULT_OPTIONS = Object.freeze<CustomizationOptions>({
  ...DEFAULT_OPTIONS,
  importErrorUX: "promptIfErrors",
  uploaderSidebarDetails: "all",
  uploaderShowSidebar: true,
  mappingStrategy: ["exact", "fuzzy", "historical_user", "historical_org"],
  skipMapping: [],
  includeExcelTemplate: true,
  // TODO: check to see what we want behavior to be in self-serve
  mappingShowSidebar: false,
})

export async function getCustomizations() {
  return caseConverterClient.get<Customization[]>("api/customizations/")
}

function makeCustomizationFormData(update: Partial<Customization>) {
  const formData = new FormData()
  if (update.label) {
    formData.append("label", update.label)
  }

  if (update.customizationKey) {
    formData.append("customizationKey", update.customizationKey)
  }

  if (update.isDefault) {
    formData.append("isDefault", "true")
  }

  if (update.options) {
    const processed = { ...update.options }
    const { illustration } = processed
    delete processed.illustrationUrl
    delete processed.illustration

    const data = humps.decamelizeKeys(processed)
    formData.append("options", JSON.stringify(data))

    if (illustration) {
      formData.append("update_illustration", JSON.stringify(true))
      if (illustration.file) {
        formData.append("illustration", illustration.file)
      }
    }
  }

  return formData
}

export interface CreateCustomizationPayload extends Partial<Customization> {
  parentId?: number
}

export async function postCustomization(update: CreateCustomizationPayload) {
  const formData = makeCustomizationFormData(update)

  if (update.parentId) {
    formData.append("parentId", `${update.parentId}`)
  }

  return caseConverterClient.postForm<Customization>("api/customizations/", formData)
}

export async function putCustomization(
  customizationId: number,
  update: Partial<Customization>,
) {
  const formData = makeCustomizationFormData(update)
  return caseConverterClient.putForm<Customization>(
    `api/customizations/${customizationId}`,
    formData,
  )
}

export async function deleteCustomization(customizationId: number) {
  return caseConverterClient.delete<{ success: boolean }>(
    `api/customizations/${customizationId}`,
  )
}
